exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/--index-copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-copy-js": () => import("./../../../src/pages/--page-2-copy.js" /* webpackChunkName: "component---src-pages-page-2-copy-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-portfolio-ai-driven-wine-recommender-js": () => import("./../../../src/pages/portfolio-ai-driven-wine-recommender.js" /* webpackChunkName: "component---src-pages-portfolio-ai-driven-wine-recommender-js" */),
  "component---src-pages-portfolio-ai-ml-platform-js": () => import("./../../../src/pages/portfolio-ai-ml-platform.js" /* webpackChunkName: "component---src-pages-portfolio-ai-ml-platform-js" */),
  "component---src-pages-portfolio-airtasker-js": () => import("./../../../src/pages/portfolio-airtasker.js" /* webpackChunkName: "component---src-pages-portfolio-airtasker-js" */),
  "component---src-pages-portfolio-data-discovery-platform-js": () => import("./../../../src/pages/portfolio-data-discovery-platform.js" /* webpackChunkName: "component---src-pages-portfolio-data-discovery-platform-js" */),
  "component---src-pages-portfolio-design-system-js": () => import("./../../../src/pages/portfolio-design-system.js" /* webpackChunkName: "component---src-pages-portfolio-design-system-js" */),
  "component---src-pages-portfolio-get-help-australia-js": () => import("./../../../src/pages/portfolio-get-help-australia.js" /* webpackChunkName: "component---src-pages-portfolio-get-help-australia-js" */),
  "component---src-pages-portfolio-larktale-js": () => import("./../../../src/pages/portfolio-larktale.js" /* webpackChunkName: "component---src-pages-portfolio-larktale-js" */),
  "component---src-pages-portfolio-lessonzone-js": () => import("./../../../src/pages/portfolio-lessonzone.js" /* webpackChunkName: "component---src-pages-portfolio-lessonzone-js" */),
  "component---src-pages-portfolio-mb-sites-campaigns-js": () => import("./../../../src/pages/portfolio-mb-sites-campaigns.js" /* webpackChunkName: "component---src-pages-portfolio-mb-sites-campaigns-js" */),
  "component---src-pages-portfolio-metro-js": () => import("./../../../src/pages/portfolio-metro.js" /* webpackChunkName: "component---src-pages-portfolio-metro-js" */),
  "component---src-pages-portfolio-mta-marketing-blog-js": () => import("./../../../src/pages/portfolio-mta-marketing-blog.js" /* webpackChunkName: "component---src-pages-portfolio-mta-marketing-blog-js" */),
  "component---src-pages-portfolio-mta-marketing-campaigns-js": () => import("./../../../src/pages/portfolio-mta-marketing-campaigns.js" /* webpackChunkName: "component---src-pages-portfolio-mta-marketing-campaigns-js" */),
  "component---src-pages-portfolio-nacion-js": () => import("./../../../src/pages/portfolio-nacion.js" /* webpackChunkName: "component---src-pages-portfolio-nacion-js" */),
  "component---src-pages-portfolio-presentation-design-js": () => import("./../../../src/pages/portfolio-presentation-design.js" /* webpackChunkName: "component---src-pages-portfolio-presentation-design-js" */),
  "component---src-pages-portfolio-rockpop-js": () => import("./../../../src/pages/portfolio-rockpop.js" /* webpackChunkName: "component---src-pages-portfolio-rockpop-js" */),
  "component---src-pages-portfolio-scs-main-js": () => import("./../../../src/pages/portfolio-scs-main.js" /* webpackChunkName: "component---src-pages-portfolio-scs-main-js" */),
  "component---src-pages-portfolio-scs-schools-js": () => import("./../../../src/pages/portfolio-scs-schools.js" /* webpackChunkName: "component---src-pages-portfolio-scs-schools-js" */),
  "component---src-pages-portfolio-sube-js": () => import("./../../../src/pages/portfolio-sube.js" /* webpackChunkName: "component---src-pages-portfolio-sube-js" */),
  "component---src-pages-portfolio-template-js": () => import("./../../../src/pages/portfolio-template.js" /* webpackChunkName: "component---src-pages-portfolio-template-js" */),
  "component---src-pages-portfolio-wushka-app-js": () => import("./../../../src/pages/portfolio-wushka-app.js" /* webpackChunkName: "component---src-pages-portfolio-wushka-app-js" */),
  "component---src-pages-portfolio-wushka-js": () => import("./../../../src/pages/portfolio-wushka.js" /* webpackChunkName: "component---src-pages-portfolio-wushka-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

